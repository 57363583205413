import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["input", "select"]
  static values = {
    required: Boolean,
  }

  connect() {
    this.displayOtherBasedOnSelectValue(this.selectTarget.value)
  }

  displayOtherInputIfSelected = (e) => {
    const value = e.target.value
    this.displayOtherBasedOnSelectValue(value)
  }

  // private

  displayOtherBasedOnSelectValue(value) {
    if (value === "other") {
      this.inputTarget.classList.remove("hidden")
      if (this.requiredValue) {
        this.inputElement.required = true
        this.selectTarget.required = false
        this.inputTarget.querySelector(".optional").classList.add("hidden")
        this.inputElement.focus()
      }
    } else {
      this.inputTarget.classList.add("hidden")
      if (this.requiredValue) {
        this.inputElement.required = false
        this.selectTarget.required = true
      }
    }
  }

  get inputElement() {
    return this.inputTarget.querySelector("input")
  }

  // set required attribute on input if select value is other and
}
