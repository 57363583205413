import { get } from "@rails/request.js"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = ["search", "results"]
  static values = {
    url: String,
  }

  search = async (e) => {
    e.preventDefault()
    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOn()
    }

    const response = await get(this.url, {
      responseKind: "turbo-stream",
    })

    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOff()
    }

    this.dispatch("complete")
  }

  reset = (e) => {
    e.preventDefault()

    this.clearForm()
    this.search(e)
  }

  clearForm = () => {
    this.form.reset()

    Array.from(this.form.elements).forEach((element) => {
      switch (element.type.toLowerCase()) {
        case "text":
        case "password":
        case "textarea":
          element.value = ""
          break
        case "radio":
        case "checkbox":
          if (!element.hasAttribute("checked")) {
            element.checked = false
          }
        default:
          break
      }
    })
  }

  // private

  get url() {
    const path = this.urlValue.length ? this.urlValue : location.pathname
    const url = new URL(location.origin + path)
    // convert the form data to a URLSearchParams object
    this.allFormData.forEach((value, key) => {
      url.searchParams.append(key, value)
    })

    return url.toString()
  }

  get allFormData() {
    const controllerFormData = this.formData

    if (this.hasSearchOutlet) {
      this.searchOutlets.forEach((outlet) => {
        const formData = outlet.formData
        formData.forEach((value, key) => {
          controllerFormData.append(key, value)
        })
      })
    }

    return controllerFormData
  }

  get formData() {
    return new FormData(this.form)
  }

  get form() {
    // if this element is a form, return it
    if (this.element.tagName === "FORM") {
      return this.element
    } else {
      // otherwise, return the first form child
      return this.element.querySelector("form")
    }
  }
}
