import swal from "sweetalert2"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    confirmText: String,
    cancelText: String,
    message: String,
    confirmed: Boolean,
  }
  connect() {
    this.form.addEventListener("submit", this.confirmRequest)
  }

  disconnect() {
    this.form.removeEventListener("submit", this.confirmRequest)
  }

  confirmRequest = (e) => {
    if (this.confirmedValue) return
    const button = e.submitter
    const element = button

    e.preventDefault()
    e.stopImmediatePropagation()

    const message = button.dataset.confirm || this.messageValue
    swal
      .fire({
        html: message,
        showCancelButton: true,
        confirmButtonColor: "#6366F1",
        cancelButtonColor: "#6B7280",
        cancelButtonText: this.cancelTextValue || "Never mind",
        confirmButtonText: this.confirmTextValue || "Okay",
      })
      .then((result) => {
        if (result.value) {
          // const confirmText = element.dataset.confirm
          this.confirmedValue = true
          element.click()

          setTimeout(() => {
            this.confirmedValue = false
          }, 10)
        }
      })
  }

  get form() {
    if (this.element.nodeName === "FORM") {
      return this.element
    } else {
      return this.element.querySelector("form")
    }
  }
}
