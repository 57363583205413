import tippy from "tippy.js"

import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  connect() {
    tippy(this.element, {
      theme: "custom",
      allowHTML: true,
    })
  }
}
