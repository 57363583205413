import ApplicationController from "../../../../javascript/application_controller"

export default class extends ApplicationController {
  static targets = ["input", "submit", "date"]

  connect() {
    this.validate()
  }

  validate = () => {
    this.submitTarget.disabled = !this.inputTarget.value.length
  }

  clearFormOnSuccess = (e) => {
    if (e.detail.success) {
      this.inputTarget.value = ""
      this.dispatch("reset-date", { target: this.dateTarget, namespace: "" })
      setTimeout(() => this.inputTarget.focus(), 10)
    }
  }
}
