import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = [
    "form",
    "buildingSelection",
    "buildings",
    "selectedBuildingsCount",
    "buildingInput",
  ]

  buildingInputTargetConnected(el) {
    const domId = el.dataset.domId

    el.checked = !!document.getElementById(domId)

    this.updateSelectedBuildingsCount()
  }

  showBuildingSelection = (e) => {
    e.preventDefault()
    this.buildingSelectionTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
  }

  hideBuildingSelection = (e) => {
    e.preventDefault()
    this.buildingSelectionTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  attachBuilding = (e) => {
    const domId = this.dataValue(e, "domId")

    const buildingTemplate = document.querySelector(
      `template[data-dom-id="${domId}"]`
    )

    this.buildingsTarget.insertAdjacentHTML(
      "beforeend",
      buildingTemplate.innerHTML
    )

    this.updateSelectedBuildingsCount()
  }

  removeBuilding = (e, withAnimation = true) => {
    e.preventDefault()
    e.stopPropagation()
    const domId = this.dataValue(e, "domId")

    // uncheck the checkbox
    const buildingInput = document.querySelector(
      `input[data-dom-id="${domId}"]`
    )
    if (buildingInput) {
      buildingInput.checked = false
    }

    const element = document.getElementById(domId)

    if (withAnimation) {
      element.classList.add("animation-out")
      element.addEventListener("animationend", () => {
        element.parentNode.removeChild(element)
        this.updateSelectedBuildingsCount()
      })
    } else {
      element.parentNode.removeChild(element)
      this.updateSelectedBuildingsCount()
    }
  }

  toggleBuilding = (e) => {
    const domId = this.dataValue(e, "domId")

    const building = document.getElementById(domId)
    if (building) {
      this.removeBuilding(e, false)
    } else {
      this.attachBuilding(e)
    }
  }

  updateSelectedBuildingsCount = () => {
    if (!this.hasSelectedBuildingsCountTarget) return

    const count = this.buildingsTarget.children.length

    this.selectedBuildingsCountTarget.textContent = `${count} selected`
  }
}
