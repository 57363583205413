import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  reset = (e) => {
    e.preventDefault()
    this.form.reset()

    Array.from(this.form.elements).forEach((element) => {
      switch (element.type.toLowerCase()) {
        case "text":
        case "password":
        case "textarea":
          element.value = ""
          break
        case "radio":
        case "checkbox":
          if (!element.hasAttribute("checked")) {
            element.checked = false
          }
        default:
          break
      }
    })

    this.form.requestSubmit()
  }

  // private

  get form() {
    return document.getElementById("filter-form")
  }
}
