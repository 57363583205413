import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["content", "button"]

  connect() {
    this.contentTarget.classList.add("max-h-0")

    if (this.data.get("open") === "true") {
      this.open()
    }
  }

  toggle = () => {
    this.contentTarget.toggleAttribute("open")

    if (this.hasButtonTarget) {
      this.buttonTarget.toggleAttribute("open")
    }

    this.contentTarget.classList.toggle("max-h-0")
    this.contentTarget.classList.toggle("max-h-[2000px]")
  }

  adjustParentHeight = () => {
    const parent = this.element.closest(`[data-controller="reveal"]`)
    const parentHeight = parent.scrollHeight
    const elementHeight = this.element.scrollHeight
    if (parent) {
      parent.style.height = `${parentHeight + elementHeight}px`
    }
  }

  open = () => {
    this.contentTarget.setAttribute("open", "")
    this.contentTarget.classList.remove("max-h-0")
    this.contentTarget.classList.add("max-h-[2000px]")
    if (this.hasButtonTarget) {
      this.buttonTarget.toggleAttribute("open")
    }
  }

  close = () => {
    this.contentTarget.removeAttribute("open")
    this.contentTarget.classList.add("max-h-0")
    this.contentTarget.classList.remove("max-h-[2000px]")
    if (this.hasButtonTarget) {
      this.buttonTarget.removeAttribute("open")
    }
  }
}
