import ApplicationController from "../application_controller"
export default class extends ApplicationController {
  static targets = ["filterCount"]

  updateFilterCount(count) {
    this.element.classList.toggle("border-indigo-500", count > 0)
    this.element.classList.toggle("hover:border-indigo-500", count > 0)

    if (this.hasFilterCountTarget) {
      this.filterCountTarget.classList.toggle("hidden", count <= 0)
      this.filterCountTarget.classList.toggle("flex", count > 0)
      this.filterCountTarget.innerText = count
    }
  }
}
