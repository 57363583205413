import { DirectUpload } from "@rails/activestorage"

export default class {
  constructor({ file, container }) {
    this.uploader = new DirectUpload(file, this.url, this)
    this.id = this.dynamicID()

    container.insertAdjacentHTML("afterbegin", this.progressBarTemplate())
  }

  start() {
    return new Promise((resolve, reject) => {
      this.uploader.create((error, blob) => {
        if (error) {
          reject({ error, id: this.id })
        } else {
          resolve({ blob, id: this.id })
        }
      })
    })
  }

  // private

  get url() {
    return document.querySelector("meta[name='direct-upload-url']").content
  }

  progressBarTemplate() {
    return `
    <div class="py-2" id="${this.id}">
      <div class="w-full bg-gray-200 rounded-full">
        <div class="bg-indigo-500 text-xs progress-bar font-medium transition-all duration-400 text-indigo-100 text-center p-0.5 leading-none rounded-full" style="width: 0%"> 0%</div>
      </div>
    </div>`
  }

  dynamicID() {
    return Math.floor(Math.random() * 1000000000)
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      "progress",
      this.directUploadDidProgress.bind(this)
    )
  }

  directUploadDidProgress(event) {
    const progress = (event.loaded / event.total) * 100

    this.progressBar.style.width = `${progress}%`
    this.progressBar.textContent = `${progress}%`
  }

  get progressBar() {
    return document.getElementById(this.id).querySelector(".progress-bar")
  }
}
