import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = { url: String }

  connect() {
    if (this.pageIsTurboPreview) return
    this.poll()
    this.timeoutRunningMs = 0
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  async poll() {
    // stop polling after 60 seconds
    if (this.timeoutRunningMs > 60000) return

    const response = await fetch(this.urlValue)
    const { status, html } = await response.json()

    if (status === "complete") {
      this.element.outerHTML = html
    } else {
      const ms = 3000 + Math.random() * 100
      this.timeoutRunningMs += ms
      this.timeout = setTimeout(() => this.poll(), ms)
    }
  }
}
