import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["loader"]

  // observe the frame for the attribute `aria-busy` and call the `frameLoading` method
  // when the attribute changes
  static values = { frameLoading: Boolean }

  connect() {
    // setup the observer
    this.observer = new MutationObserver(this.toggleLoader.bind(this))
    this.observer.observe(this.element, { attributes: true })
  }

  disconnect() {
    // disconnect the observer
    this.observer.disconnect()
  }

  toggleLoader(changes) {
    if (this.isFrameLoading(changes)) {
      this.timeout = setTimeout(() => {
        this.loaderTarget.classList.remove("hidden")
        this.loaderTarget.classList.add("flex")
      }, 250)
    } else if (this.isFrameLoaded(changes)) {
      clearTimeout(this.timeout)
      this.loaderTarget.classList.remove("flex")
      this.loaderTarget.classList.add("hidden")
    }
  }

  isFrameLoading(mutationRecords) {
    // check if the frame is loading
    return mutationRecords.some((record) => {
      return (
        record.attributeName === "busy" && this.element.hasAttribute("busy")
      )
    })
  }

  isFrameLoaded(mutationRecords) {
    // check if the frame is loaded
    return mutationRecords.some((record) => {
      return (
        record.attributeName === "complete" &&
        this.element.hasAttribute("complete")
      )
    })
  }
}
