import ApplicationController from "../application_controller"

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"

export default class extends ApplicationController {
  static values = { config: Object }
  static targets = ["dateInput", "hiddenInput"]

  connect() {
    this.picker = flatpickr(this.dateInputTarget, {
      onChange: (selectedDates, dateStr, instance) => {
        const [m, d, y] = dateStr.split(".")

        this.hiddenInputTarget.value = `${y}-${m}-${d}`
      },
      dateFormat: "m.d.Y",
      ...this.configValue,
    })
  }

  disconnect() {
    this.picker.destroy()
  }
}
