export default async ({ response }) => {
  if (![500, 404].includes(response.status)) return false

  const contentType = response.headers.get("content-type")
  if (!contentType || !contentType.includes("text/html")) return false
  const html = await response.text()
  // replace body with error page's body

  // get body of raw html
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const body = doc.body

  // replace body with error page's body
  document.body.innerHTML = body.innerHTML
}
