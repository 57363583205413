import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["button", "number"]

  reveal = (e) => {
    e.preventDefault()
    this.buttonTarget.classList.add("hidden")
    this.numberTarget.classList.remove("hidden")
  }
}
