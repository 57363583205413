import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["template", "container", "removeButton"]
  static classes = ["addAnimation", "removeAnimation"]

  connect() {
    if (this.containerTarget.children.length === 1) {
      this.removeButtonTarget.classList.add("hidden")
    }
  }

  add = (e) => {
    e.preventDefault()
    const newElement = this.templateTarget.content.cloneNode(true)
    this.removeButtonTarget.classList.remove("hidden")

    if (this.hasAddAnimationClass) {
      newElement.firstChild.classList.add(this.addAnimationClass)
    }

    this.containerTarget.appendChild(newElement)
    this.containerTarget.lastChild.querySelector("input").focus()
  }

  remove = (e) => {
    e.preventDefault()
    // remove the last element
    if (this.containerTarget.children.length > 1) {
      const lastElement = this.containerTarget.lastChild
      // if remove animation class is present, add it to the last element
      // and remove the element after the animation is done
      if (this.hasRemoveAnimationClass) {
        lastElement.classList.add("animation-out")
        lastElement.addEventListener("animationend", () => {
          this._remove(lastElement)
        })
      } else {
        this.containerTarget.removeChild(lastElement)
      }
    }
  }

  _remove(element) {
    this.containerTarget.removeChild(element)

    // hide the remove button if there is only one element left
    if (this.containerTarget.children.length === 1) {
      this.removeButtonTarget.classList.add("hidden")
    }
  }
}
