import Transition from "../lib/transition"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["content"]
  static values = { inProgress: Boolean }

  enter = async (event) => {
    await Promise.all([
      this.contentTargets.map(async (content) => {
        return new Promise((resolve) => {
          requestAnimationFrame(async () => {
            await new Transition(content, { transitioned: false }).enter(event)

            resolve()
          })
        })
      }),
    ])

    this.inProgressValue = false
  }

  leave = async (event) => {
    // if (this.inProgressValue) return

    await Promise.all([
      this.contentTargets.map(async (content) => {
        return new Promise((resolve) => {
          requestAnimationFrame(async () => {
            await new Transition(content, { transitioned: false }).leave(event)

            resolve()
          })
        })
      }),
    ])

    this.inProgressValue = false
  }
}
