import addAnimation from "../utils/add_animation"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.data.has("auto")) {
      this.showToast()
    }
  }

  showToast = async (e) => {
    let message
    if (e && e.detail && e.detail.message) {
      message = e.detail.message
    } else {
      message = this.data.get("message")
    }

    setTimeout(() => {
      this.element.textContent = message
      this.element.style.display = "block"
    }, 100)

    await addAnimation(this.element, ["toast--fade-in"])

    setTimeout(async () => {
      await addAnimation(this.element, ["toast--fade-out"])
      this.element.remove()
    }, 3000)
  }

  addToast = (e) => {
    e.preventDefault()
    toast(e.target.dataset.toastMessageParam)
  }
}

export const toast = (message) => {
  const toastTemplate = `
   <div class="toast toast--center" data-controller="toast" data-toast-message="${message}" data-toast-auto></div>
 `

  document
    .getElementById("flash_message")
    .insertAdjacentHTML("beforeend", toastTemplate)
}
