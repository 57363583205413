import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["loader", "content"]

  loaderOn() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.timeShown = Date.now()
      this.loaderTarget.classList.remove("hidden")
    }, 350)
  }

  loaderOff() {
    // If the loader was shown for less than 200ms, we'll wait for the remaining time.
    // This is to avoid the loader flickering.
    const timeLeft = 200 - (Date.now() - this.timeShown)
    if (timeLeft > 0) {
      setTimeout(() => {
        this.loaderTarget.classList.add("hidden")
        clearTimeout(this.timeout)
      }, timeLeft)
    } else {
      this.loaderTarget.classList.add("hidden")
      clearTimeout(this.timeout)
    }
  }
}
