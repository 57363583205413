import { get } from "@rails/request.js"
import errorResponseHandler from "../utils/error_response_handler"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  disconnect() {
    this.filtersValue = {}
  }

  updateFilter(key, formData, url) {
    this.filtersValue = { ...(this.filtersValue || {}), [key]: formData }
    return this.submit(url)
  }

  url(baseUrl = location.origin + location.pathname) {
    const url = new URL(baseUrl)
    if (this.filtersValue) {
      Object.keys(this.filtersValue).forEach((key) => {
        this.filtersValue[key].forEach((value, key) => {
          url.searchParams.append(key, value)
        })
      })
    }

    return url.toString()
  }

  // private

  submit = async (baseUrl) => {
    // check if baseUrl is passed in, if not, use current url
    if (!baseUrl) {
      baseUrl = location.origin + location.pathname
    }
    // check if base url has origin, if not, add origin
    if (!baseUrl.includes(location.origin)) {
      baseUrl = location.origin + baseUrl
    }

    const response = await get(this.url(baseUrl), {
      responseKind: "turbo-stream",
    })

    errorResponseHandler(response)
  }
}
