import Cleave from "cleave.js"

import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = { config: Object }

  connect() {
    this.cleave = new Cleave(this.element, this.configValue)
  }

  disconnect() {
    this.cleave.destroy()
  }
}
