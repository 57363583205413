export default (element, animations) =>
  new Promise((resolve, _) => {
    element.classList.add(...animations)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd() {
      element.classList.remove(...animations)
      element.removeEventListener("animationend", handleAnimationEnd)

      resolve("Animation ended")
    }

    element.addEventListener("animationend", handleAnimationEnd)
  })
