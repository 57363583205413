import SubmitterController from "./submitter_controller"

export default class extends SubmitterController {
  static targets = ["input"]

  reset = (e) => {
    this.inputTarget.value = ""
    this.submit()
  }
}
