import ApplicationController from "../application_controller"

import { useIntersection } from "stimulus-use"

export default class extends ApplicationController {
  connect() {
    useIntersection(this, {
      rootMargin: "100px",
    })
  }

  appear() {
    this.loadMore(this.nextUrl)
  }

  async loadMore(url) {
    const response = await fetch(url)

    const html = await response.text()
    if (response.status === 500 || response.status === 404) {
      // replace body with error page's body

      // get body of raw html
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")
      const body = doc.body

      // replace body with error page's body
      document.body.innerHTML = body.innerHTML

      return
    }

    this.element.outerHTML = html
  }

  get nextUrl() {
    return this.element.href
  }
}
