import ApplicationController from "../../../javascript/application_controller"

export default class extends ApplicationController {
  static values = {
    url: String,
    htmlTemplateId: String,
    htmlContentId: String,
    panelClasses: String,
    modalType: String,
    closeAway: Boolean,
  }

  open = () => {
    this.dispatch("open", {
      target: this.modalElement,
      detail: {
        url: this.urlValue,
        htmlTemplateId: this.htmlTemplateIdValue,
        htmlContentId: this.htmlContentIdValue,
        panelClasses: this.panelClassesValue.split(" ").filter((c) => c),
        closeAway: this.closeAwayValue,
      },
    })
  }

  get modalElement() {
    const id = this.modalTypeValue === "modal" ? "modal" : "slide-over-modal"
    return document.getElementById(id)
  }
}
