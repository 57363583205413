const defaultOptions = {
  transitioned: false,
  hiddenClass: "hidden",
  preserveOriginalClass: true,
  removeToClasses: true,
  removeEndClasses: true,
}

export default class Transition {
  constructor(element, options) {
    this.element = element
    this.options = Object.assign({}, defaultOptions, options)

    this.dataset = element.dataset

    this.leaveAfter =
      parseInt(this.dataset.leaveAfter || "") || this.options.leaveAfter || 0

    // this.options.transitioned = this.dataset.transitioned === "true"

    const {
      hiddenClass,
      preserveOriginalClass,
      removeToClasses,
      removeEndClasses,
    } = this.options

    // this.transitioned = transitioned
    this.hiddenClass = hiddenClass
    this.preserveOriginalClass = preserveOriginalClass
    this.removeToClasses = removeToClasses
    this.removeEndClasses = removeEndClasses
    // this.initialState()
  }

  // toggleTransition(event) {
  //   if (this.transitioned) {
  //     this.leave()
  //   } else {
  //     this.enter()
  //   }
  // }

  async enter(event) {
    // if (this.transitioned) return
    // this.element.dataset.transitioned = true

    const enterFromClasses = this.getAttribute("enterFrom")
    const enterActiveClasses = this.getAttribute("enterActive")
    const enterToClasses = this.getAttribute("enterTo")

    if (!!this.hiddenClass) {
      this.element.classList.remove(this.hiddenClass)
    }

    if (!this.removeToClasses) {
      this.removeClasses(this.leaveToClasses)
    }
    await this.transition(enterFromClasses, enterActiveClasses, enterToClasses)

    if (this.leaveAfter > 0) {
      setTimeout(() => {
        this.leave(event)
      }, this.leaveAfter)
    }
  }

  async leave(event) {
    // if (!this.transitioned) return
    // this.dataset.transitioned = false

    const leaveFromClasses = this.getAttribute("leaveFrom")

    const leaveActiveClasses = this.getAttribute("leaveActive")
    const leaveToClasses = this.getAttribute("leaveTo")

    if (!this.removeToClasses) {
      this.removeClasses(this.enterToClasses)
    }

    await this.transition(leaveFromClasses, leaveActiveClasses, leaveToClasses)

    if (!!this.hiddenClass) {
      this.element.classList.add(this.hiddenClass)
    }
  }

  getAttribute(name) {
    const datasetName = `transition${name[0].toUpperCase()}${name.substr(1)}`

    const classes = this.options[name] || this.dataset[datasetName] || " "
    return this.isEmpty(classes) ? [] : classes.split(" ")
  }

  async afterTransition() {
    return new Promise((resolve) => {
      const duration =
        Number(
          getComputedStyle(this.element)
            .transitionDuration.split(",")[0]
            .replace("s", "")
        ) * 1000
      setTimeout(() => {
        resolve(duration)
      }, duration)
    })
  }

  async nextAnimationFrame() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve)
      })
    })
  }

  // initialState() {
  //   this.dataset.transitioned = this.transitioned
  //   if (this.transitioned) {
  //     if (!!this.hiddenClass) {
  //       this.element.classList.remove(this.hiddenClass)
  //     }
  //     this.enter()
  //   } else {
  //     if (!!this.hiddenClass) {
  //       this.element.classList.add(this.hiddenClass)
  //     }
  //     this.leave()
  //   }
  // }

  isEmpty(str) {
    return str.length === 0 || !str.trim()
  }

  async transition(initialClasses, activeClasses, endClasses) {
    // if there's any overlap between the current set of classes and initialClasses/activeClasses/endClasses,
    // we should remove them before we start and add them back at the end

    const stashedClasses = []
    if (this.preserveOriginalClass) {
      initialClasses.forEach(
        (cls) =>
          this.element.classList.contains(cls) &&
          cls !== this.hiddenClass &&
          stashedClasses.push(cls)
      )
      activeClasses.forEach(
        (cls) =>
          this.element.classList.contains(cls) &&
          cls !== this.hiddenClass &&
          stashedClasses.push(cls)
      )
      endClasses.forEach(
        (cls) =>
          this.element.classList.contains(cls) &&
          cls !== this.hiddenClass &&
          stashedClasses.push(cls)
      )
    }

    this.addClasses(initialClasses)

    // remove the overlapping classes
    this.removeClasses(stashedClasses)

    // Add active class before element start transition and maitain it during the entire transition.
    this.addClasses(activeClasses)
    await this.nextAnimationFrame()

    // remove the initial class on frame after the beginning of the transition
    this.removeClasses(initialClasses)

    // add the endClass on frame after the beginning of the transition
    this.addClasses(endClasses)

    // dynamically comput the duration of the transition from the style of the element
    await this.afterTransition()

    // remove both activeClasses and endClasses
    this.removeClasses(activeClasses)
    if (this.removeEndClasses) {
      this.removeClasses(endClasses)
    }

    // restore the overlaping classes
    this.addClasses(stashedClasses)
  }

  addClasses(classes) {
    if (classes.length > 0) {
      this.element.classList.add(...classes)
    }
  }

  removeClasses(classes) {
    if (classes.length > 0) {
      this.element.classList.remove(...classes)
    }
  }
}
