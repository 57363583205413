import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["entity", "address", "pw_building", "pw_portfolio"]
  static values = {
    entity: Boolean,
    address: Boolean,
    pw_building: Boolean,
    pw_portfolio: Boolean,
  }
  change = (event) => {
    // get the value of the selected option
    // if it matches one of the targets, show all targets that match if it's checked

    const selectedValue = event.target.value

    if (!this.isValidTarget(selectedValue)) return

    this[`${selectedValue}Targets`].forEach((target) => {
      // if (this.willBeAllHidden(selectedValue)) return
      this[`${selectedValue}Value`] = event.target.checked
      target.classList.toggle("hidden", !event.target.checked)
    })
  }

  isValidTarget(value) {
    return this[`has${this.capitalize(value)}Target`]
  }

  willBeAllHidden(value) {
    return (
      ["entity", "address", "pw_building", "pw_portfolio"].filter((v) => {
        if (v === value) return false

        return this[`${v}Value`]
      }).length >= 3
    )
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
