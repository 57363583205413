import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["checkbox", "hiddenBuilding", "applyButton"]

  connect() {
    this.updateApplyButton()
  }

  checkboxTargetConnected(el) {
    if (this.hiddenBuildingTargets.some((target) => target.value == el.value)) {
      el.checked = true
    }
  }

  reset = (e) => {
    e.preventDefault()

    this.checkboxTargets.forEach((el) => (el.checked = false))
    this.hiddenBuildingTargets.forEach((el) => el.remove())
    this.updateApplyButton()
  }

  toggle = (e) => {
    const { target } = e
    const { value, checked } = target

    if (checked) {
      this.element.appendChild(this.hiddenInput(value))
    } else {
      this.hiddenBuildingTargets.forEach((el) => {
        if (el.value == value) {
          el.remove()
        }
      })
    }
    this.updateApplyButton()
  }

  // private

  updateApplyButton() {
    const selectedCount = this.hiddenBuildingTargets
      .map((el) => el.value)
      .filter((value, index, self) => self.indexOf(value) === index).length

    if (selectedCount > 0) {
      this.applyButtonTarget.textContent = `Show results for ${selectedCount} building(s)`
    } else {
      this.applyButtonTarget.textContent = "Show results for all buildings"
    }
  }

  get selectedCount() {
    return this.hiddenBuildingTargets
      .map((el) => el.value)
      .filter((value, index, self) => self.indexOf(value) === index).length
  }

  hiddenInput(value) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "buildings[]"
    input.dataset.buildingsFilterTarget = "hiddenBuilding"
    input.value = value
    return input
  }
}
