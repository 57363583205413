import { get } from "@rails/request.js"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = ["filter-submitter", "results"]
  static values = {
    sortData: Object,
  }

  perform = async (e) => {
    e.preventDefault()
    this.sortDataValue = {
      sort_direction: this.dataValue(e, "sortDirection"),
      sort_column: this.dataValue(e, "sortColumn"),
    }

    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOn()
    }
    await get(this.url, {
      responseKind: "turbo-stream",
    })
    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOff()
    }
  }

  get url() {
    const url = new URL(this.filterSubmitterOutlet.url())
    Object.keys(this.sortDataValue).forEach((key) => {
      url.searchParams.append(key, this.sortDataValue[key])
    })
    return url.toString()
  }
}
