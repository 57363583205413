import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["loader", "image"]
  static values = { url: String }

  connect() {
    if (!this.pageIsTurboPreview) setTimeout(this.createImage)
  }

  updateImage = (e) => {
    e.preventDefault()

    const { url } = e.detail
    this.loaderTarget.classList.remove("hidden")

    if (this.hasImageTarget) {
      this.imageTarget.parentElement.removeChild(this.imageTarget)
    }
    this.createImage(url)
  }

  createImage = (url) => {
    url = url || this.urlValue

    if (!url) return

    const image = new Image()
    Object.keys(this.imageOptions).forEach((key) => {
      image[key] = this.imageOptions[key]
    })
    if (this.imageClasses.length) {
      image.classList.add(...this.imageClasses)
    }
    image.setAttribute(`data-${this.identifier}-target`, "image")
    image.onload = () => {
      this.element.appendChild(image)
      this.loaderTarget.classList.add("hidden")
    }
    image.src = url
  }

  // private

  get imageOptions() {
    const { height, width, alt } = this.element.dataset
    const options = { height, width, alt }

    return this.compact(options)
  }

  get imageClasses() {
    const string = this.element.dataset.class || ""
    return string.split(/\s+/).filter((value) => value)
  }

  compact(object) {
    const result = {}

    Object.keys(object).forEach((key) => {
      if (object[key]) {
        result[key] = object[key]
      }
    })

    return result
  }
}
