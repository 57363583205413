import { addValidator } from "stimulus-form-validation"

function passwordStrength(element) {
  return new Promise(async (resolve, reject) => {
    if (!window.zxcvbn) {
      const script = document.createElement("script")
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js"

      script.onload = () => {
        const result = window.zxcvbn(element.value)
        const score = result.score
        const warning = result.feedback.warning

        if (score < 2) {
          return reject(warning)
        } else {
          return resolve()
        }
      }

      document.body.appendChild(script)
    } else {
      const result = window.zxcvbn(element.value)
      const score = result.score
      const warning = result.feedback.warning || "Password is weak"
      if (score < 2) {
        return reject(warning)
      } else {
        return resolve()
      }
    }
  })
}

addValidator("password-strength", passwordStrength, 5000)
