import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    minHeight: Number,
    keepExpanded: Boolean,
  }

  connect() {
    if (this.hasMinHeightValue) {
      this.element.style.minHeight = `${this.minHeightValue}px`
    }

    if (this.hasKeepExpandedValue) {
      this.autoExpand()
    }
  }

  get textarea() {
    return this.element.querySelector("textarea")
  }

  autoExpand = () => {
    this.textarea.style.height = "inherit"
    this.textarea.style.height = this.expenedHeight() + "px"
  }

  collapseHeight = () => {
    if (this.hasKeepExpandedValue) return

    this.textarea.style.height = "100px"
  }

  expenedHeight() {
    const computed = window.getComputedStyle(this.textarea)

    return [
      computed.borderTopWidth,
      computed.borderBottomWidth,
      computed.paddingTop,
      this.textarea.scrollHeight,
      computed.paddingBottom,
    ].reduce((sum, currentValue) => {
      return sum + parseInt(currentValue, 10)
    }, 0)
  }
}
