import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = ["filter-submitter", "sort"]
  static values = {
    format: { type: String, default: "csv" },
  }

  download = (e) => {
    e.preventDefault()
    const tempAnchor = document.createElement("a")
    tempAnchor.href = this.url
    document.body.appendChild(tempAnchor)
    tempAnchor.click()
    document.body.removeChild(tempAnchor)
  }

  // private

  get url() {
    const url = new URL(this.filterSubmitterOutlet.url())
    const sortParams = this.sortOutlet.sortDataValue

    Object.keys(sortParams).forEach((key) => {
      url.searchParams.append(key, sortParams[key])
    })

    url.searchParams.set("format", this.formatValue)
    return url.toString()
  }
}
