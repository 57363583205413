import ApplicationController from "../application_controller"
import { destroy } from "@rails/request.js"
export default class extends ApplicationController {
  static values = {
    nodes: Array,
    destroyUrl: String,
    editUrl: String,
    detailsUrl: String,
    addUrl: String,
    buildingName: String,
    permissions: Object,
  }

  static targets = ["chart", "emptyState", "actions"]

  async connect() {
    this.emptyStateTarget.classList.toggle("hidden", this.nodesValue.length > 0)
    this.actionsTarget.classList.toggle("hidden", this.nodesValue.length == 0)
    const { default: OrgChart } = await import("../vendor/OrgChart.js")

    this.OrgChart = OrgChart

    if (this.nodesValue.length) {
      this.initializeChart(this.nodesValue)
    }
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

  initializeChart(nodes) {
    this.OrgChart.templates.ana.field_0 =
      '<text class="field_0" style="font-size: 20px;" x="125" y="30" fill="#ffffff" text-anchor="middle">{val}</text>'
    this.OrgChart.templates.ana.field_1 =
      '<text class="field_1" style="font-size: 14px;" x="125" y="50" fill="#ffffff" text-anchor="middle">{val}% Share</text>'
    this.OrgChart.templates.ana.field_2 =
      '<text class="field_2" style="font-size: 14px;" x="125" y="70" fill="#ffffff" text-anchor="middle">BVI: {val}</text>'
    this.chart = new this.OrgChart(this.chartTarget, {
      nodeMenu: this.nodeMenu(),
      nodeBinding: {
        field_0: "name",
        field_1: "share",
        field_2: "bvi",
      },
      nodes: nodes,
    })

    this.chart.on("click", (sender, args) => {
      const url = decodeURIComponent(this.detailsUrlValue).replace(
        ":id",
        args.node.id
      )

      this.openModal(url)

      return false
    })

    this.chart.onInit(() => {
      this.emptyStateTarget.classList.add("hidden")
      this.actionsTarget.classList.remove("hidden")
    })
  }

  addEntity = ({ detail }) => {
    if (this.chart) {
      this.chart.addNode(detail.node)
    } else {
      this.initializeChart([detail.node])
    }
  }

  exportPdf = () => {
    this.chart.exportPDF({
      filename: `${this.buildingNameValue} entities.pdf`,
      expandChildren: true,
    })
  }

  updateEntity = ({ detail }) => {
    this.chart.updateNode(detail.node)
  }

  async removeEntity(nodeID) {
    await destroy(this.destroyUrlValue.replace(":id", nodeID))

    // if it's the last node we destroy the chart else we just remove the node
    if (Object.keys(this.chart.nodes).length == 1) {
      this.chart.destroy()
      this.chart = null
      this.chartTarget.style = ""
      this.emptyStateTarget.classList.remove("hidden")
      this.actionsTarget.classList.add("hidden")
    } else {
      this.chart.removeNode(nodeID)
    }
  }

  addRootEntity = (e) => {
    e.preventDefault()
    const url = decodeURIComponent(this.addUrlValue).replace(":id", "")

    this.openModal(url)
  }

  action(name, nodeId) {
    if (name == "remove") {
      return this.removeEntity(nodeId)
    }

    const url = decodeURIComponent(this[`${name}UrlValue`]).replace(
      ":id",
      nodeId
    )

    this.openModal(url)
  }

  nodeMenu() {
    const actions = [
      "details",
      ...Object.keys(this.permissionsValue).filter(
        (action) => this.permissionsValue[action]
      ),
    ]
    const results = {}

    actions.forEach((action) => {
      results[action] = {
        // action should be capitalized
        text: action.charAt(0).toUpperCase() + action.slice(1),
        onClick: (nodeId) => this.action(action, nodeId),
      }
    })

    return results
  }

  openModal = (url) => {
    const modalController = document.getElementById("slide-over-modal")
    modalController.dispatchEvent(
      new CustomEvent("modal-open-button:open", {
        detail: { url },
      })
    )
  }
}
