import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["text"]
  static values = {
    text: String,
  }

  copy = (e) => {
    e.preventDefault()

    navigator.clipboard.writeText(this.textValue).then(() => {
      this.textTarget.classList.add("text-green-500")
      this.textTarget.innerText = "Copied!"

      setTimeout(() => {
        this.textTarget.classList.remove("text-green-500")
        this.textTarget.innerText = this.textValue
      }, 1000)
    })
  }
}
