import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  dispatch = (
    eventName,
    {
      target = this.element,
      detail = {},
      bubbles = true,
      cancelable = true,
      namespace = this.identifier,
      controller = "",
    } = {}
  ) => {
    const type = namespace ? `${this.identifier}:${eventName}` : eventName

    const event = new CustomEvent(type, { detail, bubbles, cancelable })

    if (controller) {
      document
        .querySelectorAll(`[data-controller*="${controller}"]`)
        .forEach((c) => c.dispatchEvent(event))
    } else {
      target.dispatchEvent(event)
    }

    return event
  }

  preventDefault(e) {
    e.preventDefault()
  }

  dataValue(event, value) {
    // debugger
    return (
      event.target.dataset[value] ||
      (event.currentTarget &&
        event.currentTarget.dataset &&
        event.currentTarget.dataset[value])
    )
  }

  _preventDefault(event) {
    if (!event) return
    if (this.dataValue(event, "noPrevent") !== undefined) return

    event.preventDefault()
  }

  get classList() {
    return this.element.classList
  }

  observeMutations(
    callback,
    target = this.element,
    options = { childList: true, subtree: true }
  ) {
    const observer = new MutationObserver((mutations) => {
      observer.disconnect()
      Promise.resolve().then(start)
      callback.call(this, mutations)
    })
    function start() {
      if (target.isConnected) observer.observe(target, options)
    }
    start()
  }

  get pageIsTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
}
