import { StreamActions } from "@hotwired/turbo"

StreamActions.dispatch_event = function () {
  const eventName = this.getAttribute("name") || ""
  let detail = this.getAttribute("detail")

  if (detail) {
    try {
      detail = JSON.parse(detail)
    } catch (error) {
      console.error(error)
    }
  }

  if (!eventName) {
    console.error("No event name provided")
    return
  }

  this.targetElements.forEach((element) => {
    // dispatch a custom event on the element
    // the detail property is the detail of the element

    element.dispatchEvent(
      new CustomEvent(eventName, {
        detail: detail || {},
      })
    )
  })
}
