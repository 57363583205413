import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["submit"]
  static values = {
    submitOnConnect: Boolean,
  }

  connect() {
    if (this.submitOnConnectValue) {
      this.submit()
    }
  }

  submit = (e) => {
    if (e) {
      if (e.target === this.submitTarget) return
      this._preventDefault(e)
    }

    this.submitTarget.click()
  }

  debounceSubmit = (e) => {
    if (e.target === this.submitTarget) return
    this._preventDefault(e)

    this.debounce(this.submit, 500)
  }

  debounce = (fn, delay) => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(fn, delay)
  }
}
