import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static outlets = ["filter-submitter", "filter-button", "results"]
  static values = {
    filterName: String,
    url: String,
  }

  apply = async (e) => {
    e.preventDefault()
    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOn()
    }

    await this.filterSubmitterOutlet.updateFilter(
      this.filterNameValue,
      this.formData,
      this.urlValue
    )

    this.dispatch("applied")

    if (this.hasResultsOutlet) {
      this.resultsOutlet.loaderOff()
    }

    if (this.hasFilterButtonOutlet) {
      this.filterButtonOutlet.updateFilterCount(this.filterActiveCount())
    }
  }

  // private

  get form() {
    if (this.element.tagName == "FORM") {
      return this.element
    } else {
      return this.element.querySelector("form")
    }
  }

  // function that returns if any filter is active
  isAnyFilterActive() {
    return this.filterActiveCount() > 0
  }

  filterActiveCount() {
    return this.form.querySelectorAll("input:checked").length
  }

  get formData() {
    return new FormData(this.form)
  }

  reset = (e) => {
    e.preventDefault()
    this.form.reset()

    Array.from(this.form.elements).forEach((element) => {
      switch (element.type.toLowerCase()) {
        case "text":
        case "password":
        case "textarea":
          element.value = ""
          break
        case "radio":
        case "checkbox":
          if (!element.hasAttribute("checked")) {
            element.checked = false
          }
        default:
          break
      }
    })

    this.form.requestSubmit()
  }
}
