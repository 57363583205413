import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["input", "progress"]

  connect() {
    this.update()
  }

  update = (e) => {
    e && e.stopPropagation()

    if (
      this.inputTargets[0].checked &&
      this.progressTargets[0].classList.contains("active")
    ) {
      this.progressTargets[0].classList.remove("active")
      this.inputTargets[0].checked = false
    }

    const checkedIndex = this.inputTargets.findIndex((input) => input.checked)

    this.progressTargets.forEach((progress, index) => {
      if (index <= checkedIndex) {
        progress.classList.add("active")
      } else {
        progress.classList.remove("active")
      }
    })
  }

  reset = (e) => {
    e && e.stopPropagation()

    this.inputTargets.forEach((input) => {
      input.checked = false
    })

    this.progressTargets.forEach((progress) => {
      progress.classList.remove("active")
    })
  }
}
