import ApplicationController from "../application_controller"
import { createPopper } from "@popperjs/core"
export default class extends ApplicationController {
  static targets = ["menu", "button", "buttonText", "angleIcon", "arrow"]
  static values = {
    open: Boolean,
    defaultButtonText: String,
    selectItem: String,
    inProgress: Boolean,
  }
  static classes = [
    "toggle",
    "visible",
    "invisible",
    "active",
    "selected",
    "entering",
    "leaving",
    "rotate",
  ]

  connect() {
    const id = this.createId()
    document.addEventListener("keydown", this._onMenuButtonKeydown)
    if (this.hasButtonTarget) {
      this.buttonTarget.setAttribute("aria-haspopup", "true")
      this.buttonTarget.setAttribute("aria-expanded", "false")
      this.buttonTarget.setAttribute("aria-controls", id)
    }
    this.menuTarget.setAttribute("id", id)

    this.popper = createPopper(this.buttonTarget, this.menuTarget, {
      placement: "bottom-end",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    })
  }

  disconnect() {
    document.addEventListener("keydown", this._onMenuButtonKeydown)
  }

  selectItemValueChanged(value) {
    if (value.length) {
      this.buttonTextTarget.textContent = value
    }

    if (this.hasSelectedClass) {
      this.buttonTarget.classList.toggle(
        this.selectedClass,
        this.buttonTextTarget.textContent !== this.defaultButtonTextValue
      )
    }

    this.openValue = false
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener(
        "keydown",
        this._onMenuButtonKeydown
      )
    }
  }

  toggle() {
    if (this.inProgress) return

    this.inProgress = true

    this.openValue = !this.openValue
  }

  openValueChanged(open) {
    this._rotateIcon(open)
    open ? this._show() : this._hide()

    if (open) {
      document.addEventListener("click", this.hide)

      this._show()
    } else {
      document.removeEventListener("click", this.hide)
      this._hide()
    }
  }

  _onMenuButtonKeydown = (event) => {
    if (!this.openValue) return
    switch (event.keyCode) {
      // case 13: // enter
      // case 32: // space
      case 27:
        event.preventDefault()
        this._hide()
    }
  }

  show = (e) => {
    this.openValue = true
  }

  selectItem = (event) => {
    event.preventDefault()

    this.selectItemValue =
      event.target.textContent.trim() || event.target.dataset.value
  }

  reset = () => {
    this.buttonTextTarget.textContent = this.defaultButtonTextValue

    if (this.hasSelectedClass) {
      this.buttonTarget.classList.remove(this.selectedClass)
    }
    this.openValue = false
  }

  hide = (event) => {
    if (!this.element.contains(event.target) && this.openValue) {
      this.openValue = false
    }
  }

  hideOnFormSuccess = (event) => {
    if (event.detail.success) {
      this.openValue = false
    }
  }

  // private

  _rotateIcon(open) {
    if (this.hasAngleIconTarget) {
      this.angleIconTarget.classList.toggle(this.rotateClass, open)
    }
  }

  _show = () => {
    this.buttonTarget.setAttribute("aria-expanded", "true")
    this.buttonTarget.setAttribute("open", "true")
    // this.openOverlay()
    this.menuTarget.classList.remove(this.toggleClass)
    this.popper.update()
    if (this.hasActiveClass) {
      this.buttonTarget.classList.add(
        this.activeClass,
        `hover:${this.activeClass}`
      )
    }
    setTimeout(() => {
      this.menuTarget.classList.add(...this.enteringClass.split(" "))
      this.menuTarget.classList.remove(...this.invisibleClass.split(" "))
      setTimeout(() => {
        this.inProgress = false
        this.menuTarget.classList.remove(...this.enteringClass.split(" "))
      }, 100)
    })
  }

  _hide(cb) {
    // this.closeOverlay()
    setTimeout(() => {
      this.menuTarget.classList.add(...this.invisibleClass.split(" "))
      this.menuTarget.classList.remove(...this.visibleClass.split(" "))
      this.menuTarget.classList.add(...this.leavingClass.split(" "))
      if (this.hasActiveClass) {
        this.buttonTarget.classList.remove(
          this.activeClass,
          `hover:${this.activeClass}`
        )
      }
      setTimeout(() => {
        this.menuTarget.classList.remove(...this.leavingClass.split(" "))

        this.menuTarget.classList.add(this.toggleClass)
        this.inProgress = false
        this.openValue = false
      }, 75)

      this.buttonTarget.removeAttribute("aria-expanded")
      this.buttonTarget.removeAttribute("open", "true")
    })
  }

  createId() {
    return Math.random().toString(36).slice(2)
  }

  openOverlay() {
    // create a div with tailwind classes
    // the opacity should be like 90%
    // attach it to body
    this.buttonTarget.classList.add("z-40", "absolute", "border-indigo-500")
    const overlay = document.createElement("div")
    overlay.id = "overlay"
    overlay.classList.add(
      "fixed",
      "inset-0",
      "z-10",
      "bg-gray-200",
      "bg-opacity-40"
    )
    document.body.appendChild(overlay)
  }

  closeOverlay() {
    const overlay = document.querySelector("#overlay")
    if (!overlay) return

    document.body.removeChild(overlay)
  }
}
