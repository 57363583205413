import { StreamActions } from "@hotwired/turbo"

StreamActions.animate_remove = function () {
  const animationClass = this.getAttribute("animation") || ""
  const { dataset } = this

  this.targetElements.forEach((element) => {
    Object.keys(dataset).forEach((key) => {
      element.dataset[key] = dataset[key]
    })
    element.classList.add(...animationClass.split(" "))
    element.addEventListener("animationend", () => {
      element.parentNode.removeChild(element)
    })
  })
}
