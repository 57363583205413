import Transition from "../../../javascript/lib/transition"
import ApplicationController from "../../../javascript/application_controller"

const defaultPanelContainerClass = "panel-container"
const defaultPanelClass = "modal-panel"

export default class extends ApplicationController {
  static targets = [
    "backdrop",
    "panel",
    "content",
    "placeholder",
    "panelContainer",
  ]
  static values = {
    open: Boolean,
    closeAway: { type: Boolean, default: true },
    inProgress: Boolean,
    modalType: { type: String, default: "modal" },
  }

  disconnect() {
    // if (!this.closeAwayValue) return
    document.removeEventListener("keyup", this.closeOnEscape)
    // document.removeEventListener("click", this.closeAway)
  }

  open = async (e) => {
    if (this.openValue) return
    if (this.inProgressValue) return
    e.preventDefault()
    e.stopImmediatePropagation()
    this.openValue = true
    this.inProgressValue = true
    const {
      url,
      htmlTemplateId,
      htmlContentId,
      panelClasses,
      panelContainerClasses,
      closeAway,
    } = e.detail

    this.htmlContentId = htmlContentId
    this.closeAwayValue = !!closeAway
    this.lockScroll()

    // remove all classes from panel
    this.panelTarget.classList.remove(...this.panelTarget.classList)

    // add panel container classes

    if (this.hasPanelContainerTarget) {
      const classes = panelContainerClasses || [defaultPanelContainerClass]
      this.panelContainerTarget.classList.add(...classes)
    }
    // add panel classes
    if (this.modalTypeValue === "modal") {
      if (panelClasses.length) {
        this.panelTarget.classList.add(...panelClasses)
      } else {
        this.panelTarget.classList.add(defaultPanelClass)
      }
    }

    this.contentTarget.innerHTML = ""

    const timeout = setTimeout(() => {
      this.placeholderTarget.classList.remove("hidden")
    }, 100)

    await Promise.all([
      ...this.transitionIn(),
      this.loadContent({ htmlTemplateId, url, htmlContentId }).then(() => {
        clearTimeout(timeout)
        this.placeholderTarget.classList.add("hidden")
      }),
    ])

    this.installCloseAwayListener()

    this.inProgressValue = false
  }

  transitionIn() {
    if (this.modalTypeValue === "modal") {
      return [
        this.transition({ element: this.backdropTarget, direction: "enter" }),
        this.transition({ element: this.panelTarget, direction: "enter" }),
      ]
    } else {
      this.element.classList.remove("hidden")
      return [
        this.transition({ element: this.panelTarget, direction: "enter" }),
      ]
    }
  }

  // direction: enter | leave
  transition = ({ element, direction }) => {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        const transition = new Transition(element, { transitioned: false })
        transition[direction]().then(() => {
          resolve()
        })
      })
    })
  }

  async loadContent({ htmlTemplateId, url }) {
    if (htmlTemplateId) {
      this.loadContentFromTemplate(htmlTemplateId)
      return Promise.resolve()
    } else if (this.htmlContentId) {
      const content = document.getElementById(this.htmlContentId)
      this.contentTarget.appendChild(content)
      content.classList.remove("hidden")
      return Promise.resolve()
    } else {
      return this.loadContentFromUrl(url)
    }
  }

  loadContentFromTemplate(htmlTemplateId) {
    const template = document.getElementById(htmlTemplateId)
    const content = template.content.cloneNode(true)
    this.contentTarget.appendChild(content)
  }

  loadContentFromUrl(url) {
    // disable cors
    return fetch(url, {
      headers: { Accept: "text/vnd.turbo-stream.html, text/html" },
      mode: "no-cors",
    })
      .then((response) => response.text())
      .then((html) => {
        this.contentTarget.innerHTML = html
      })
  }

  close = async (event) => {
    if (!this.openValue) return
    if (this.inProgressValue) return
    const isCloseButton = this.dataValue(event, "modalCloseButton") === "true"

    if (!isCloseButton) return
    this.openValue = false
    event.preventDefault()
    event.stopImmediatePropagation()

    await this.closeModal()
    this.inProgressValue = false
  }

  closeModal = async () => {
    this.openValue = false
    this.unlockScroll()

    document.removeEventListener("keyup", this.closeOnEscape)
    document.removeEventListener("click", this.closeAway)

    if (this.htmlContentId) {
      // move content back to body
      const content = document.getElementById(this.htmlContentId)
      content.classList.add("hidden")
      document.body.appendChild(content)
    }

    await this.transitionOut()
  }

  async transitionOut() {
    if (this.modalTypeValue === "modal") {
      return Promise.all([
        new Transition(this.backdropTarget, { transitioned: true }).leave(),
        new Transition(this.panelTarget, { transitioned: true }).leave(),
      ])
    } else {
      await Promise.all([
        new Transition(this.panelTarget, { transitioned: true }).leave(),
      ])
      this.element.classList.add("hidden")
    }
  }

  closeOnEscape = async (event) => {
    if (!this.openValue || this.inProgressValue) return

    if (event.key === "Escape") {
      await this.closeModal(event)
    }

    this.inProgressValue = false
  }

  closeOnFormSuccess = (e) => {
    if (e.detail.success) {
      this.closeModal()
    }
  }

  closeAway = async (event) => {
    if (!this.openValue || this.inProgressValue) return
    // Close the modal if the user clicks outside of the modal panel
    // check if target has class air-datepicker-cell
    const calendarCell = event.target.classList.contains("air-datepicker-cell")
    const isModalOpenButton =
      this.dataValue(event, "modalOpenButton") === "true"
    if (
      !this.isFlatpickrElement(event.target) &&
      !calendarCell &&
      !isModalOpenButton &&
      !this.panelTarget.contains(event.target) &&
      event.target.parentElement
    ) {
      event.preventDefault()
      event.stopImmediatePropagation()
      await this.closeModal()
    }

    this.inProgressValue = false
  }

  installCloseAwayListener() {
    // if (!this.closeAwayValue) return

    document.addEventListener("keyup", this.closeOnEscape)
    // document.addEventListener("click", this.closeAway)
  }

  isFlatpickrElement = (element) => {
    const flatpickrElements = Array.from(
      document.querySelectorAll(".flatpickr-calendar")
    )
    return flatpickrElements.some((flatpickrElement) =>
      flatpickrElement.contains(element)
    )
  }

  swalOpen() {
    return document.body.classList.contains("swal2-shown")
  }

  // Based on https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/modal.js
  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add("overflow-hidden")

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove("overflow-hidden")

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition
  }
}
