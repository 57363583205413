import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["content", "button"]
  static classes = ["content"]

  static values = {
    showText: String,
    hideText: String,
  }

  hideContent = (e) => {
    e.preventDefault()

    this.contentTargets.forEach((el) =>
      el.classList.add(this.getContentClass(el))
    )
    this.changeButtonText(true)
  }

  showContent = (e) => {
    e.preventDefault()

    this.contentTargets.forEach((el) =>
      el.classList.remove(this.getContentClass(el))
    )
    this.changeButtonText(false)
  }

  toggleContent = (e) => {
    e.preventDefault()

    this.contentTargets.forEach((el) =>
      el.classList.toggle(this.getContentClass(el))
    )
    this.toggleButtonText()
  }

  changeButtonText(bool) {
    if (!this.hasButtonTarget) return
    const buttonText = bool ? this.showTextValue : this.hideTextValue

    this.buttonTarget.textContent = buttonText
  }

  toggleButtonText() {
    if (!this.hasButtonTarget) return

    const buttonText =
      this.buttonTarget.textContent === this.showTextValue
        ? this.hideTextValue
        : this.showTextValue

    this.buttonTarget.textContent = buttonText
  }

  // private

  getContentClass(el) {
    const elToggleContentClass = el.dataset.toggleContentClass
    if (elToggleContentClass) return elToggleContentClass
    if (this.hasContentClass) return this.contentClass

    return "hidden"
  }
}
