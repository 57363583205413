import * as Turbo from "@hotwired/turbo"
import "sweetalert2/dist/sweetalert2.min.css"
import LocalTime from "local-time"
import { registerControllers } from "stimulus-vite-helpers"
import { ValidationController, config } from "stimulus-form-validation"
import RevealController from "../controllers/reveal_controller"
import { Application } from "@hotwired/stimulus"
import "../validators/password"

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeFullReload", (data) => {
    Turbo.visit(window.location)
    throw new Error("Full reload hack! Please ignore this error")
  })
}
// import "@wizardhealth/stimulus-multiselect/src/multiselect.css"
import.meta.globEager("../custom_turbo_actions/*.js")
Turbo.start()
LocalTime.start()
const application = Application.start()

// Configure Stimulus development experience
application.debug = false
// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

application.register("validation", ValidationController)
application.register("custom-permission-reveal", RevealController)
registerControllers(
  application,
  import.meta.globEager("../controllers/*_controller.js")
)

const componentControllers = import.meta.globEager(
  "../../views/components/**/controller.js"
)

for (let path in componentControllers) {
  let module = componentControllers[path]
  let name = path
    .match(/.+\/views\/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("_", "-")
    .replaceAll("/", "--")
  application.register(name, module.default)
}

// Manually register Flatpickr as a stimulus controller

// const controllers = import.meta.globEager("./**/*_controller.js")

// for (let path in controllers) {
//   let module = controllers[path]
//   let name = path.match(/\.\/(.+)_controller\.js$/)[1].replaceAll("/", "--")
//   application.register(name, module.default)
// }

// registerControllers(application, import.meta.globEager("./*controller.js"))

config({
  containerSelector: ".basic-form__container-wrapper",
  errorMessageClass: "basic-form__field-error",
  containerErrorClass: "basic-form__container-wrapper--error",
  disableSubmitButtons: true,
})

const styles = import.meta.globEager("../stylesheets/**/*.scss")
const componentStyles = import.meta.globEager(
  "../../views/components/**/*.scss"
)

document.addEventListener("turbo:submit-start", (event) => {
  const { formSubmission } = event.detail
  const { formElement, submitter } = formSubmission

  if (event.target.hasAttribute("data-turbo-stream")) {
    event.detail.formSubmission.fetchRequest.headers["Accept"] =
      "text/vnd.turbo-stream.html"
  }

  Array.from(formElement.elements).forEach((el) => {
    if (el.type === "submit") {
      el.disabled = true
    }
  })
  if (!submitter) return
  if (!submitter.dataset.disableWith) return
  setTimeout(() => {
    const orignalContent = submitter.innerHTML
    submitter.dataset.originalContent = orignalContent
    submitter.innerHTML = submitter.dataset.disableWith
  })
})

document.addEventListener("turbo:submit-end", ({ detail }) => {
  const { formElement, submitter } = detail.formSubmission
  if (!submitter) return
  Array.from(formElement.elements).forEach((el) => {
    if (el.type === "submit") {
      el.disabled = false
    }
  })
  if (!submitter.dataset.disableWith) return

  submitter.innerHTML = submitter.dataset.originalContent
})

document.addEventListener("turbo:visit", (event) => {
  // if url includes bank then dont record forms and hide numbers
  // else record forms and show numbers
  if (!window.smartlook) return
  const { url } = event.detail

  if (url.includes("bank")) {
    smartlook("record", {
      forms: false,
      numbers: false,
      emails: false,
      ips: false,
    })
  } else {
    smartlook("record", { forms: true, numbers: true, emails: true, ips: true })
  }
})
