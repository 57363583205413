import { Turbo } from "@hotwired/turbo-rails"
import { post, patch } from "@rails/request.js"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    publicTokenUrl: String,
    exchangeTokenUrl: String,
    updateAccountsUrl: String,
    updateMode: Boolean,
  }

  async start() {
    this.element.disabled = true
    window.Plaid.create({
      token: await this.getPlaidToken(),
      onSuccess: async (public_token, metadata) => {
        this.element.disabled = false
        if (this.updateModeValue) {
          const response = await patch(this.updateAccountsUrlValue, {
            body: JSON.stringify({ accounts: metadata["accounts"] }),
            headers: { "Content-Type": "application/json" },
          })

          const { redirect_url } = await response.json

          Turbo.visit(redirect_url)
        } else {
          const response = await post(this.exchangeTokenUrlValue, {
            body: JSON.stringify({
              public_token,
              accounts: metadata["accounts"],
              institution: metadata["institution"],
            }),
            headers: { "Content-Type": "application/json" },
          })

          const { redirect_url } = await response.json

          Turbo.visit(redirect_url)
        }
      },
      onLoad: () => {},
      onExit: (err, metadata) => {
        this.element.disabled = false
      },
      onEvent: (eventName, metadata) => {},
      //required for OAuth; if not using OAuth, set to null or omit:
      // receivedRedirectUri: window.location.href,
    }).open()
  }

  getPlaidToken = async () => {
    const response = await post(this.publicTokenUrlValue, {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ product: this.productValue }),
    })

    const { link_token } = await response.json
    return link_token
  }
}
