import ApplicationController from "../application_controller"
export default class extends ApplicationController {
  toggleFullView(event) {
    event.preventDefault()

    // if data-full-view is set then remove it, otherwise set it
    if (document.body.dataset.fullView) {
      document.body.removeAttribute("data-full-view")
    } else {
      document.body.dataset.fullView = true
    }
  }
}
