import Uploader from "../lib/uploader"
import { post } from "@rails/request.js"
import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static values = {
    url: String,
    multiple: Boolean,
  }

  static targets = ["documents", "document", "error"]

  upload = (event) => {
    let files = event.target.files || event.currentTarget.files
    files = Array.from(files).forEach(async (file) => {
      const uploader = new Uploader({ file, container: this.documentsTarget })

      try {
        const { blob, id } = await uploader.start()

        this.errorTarget.classList.add("hidden")
        // make a request to your server to save the blob
        // and then remove the progress bar

        if (!this.multipleValue) {
          this.documentTargets.forEach((document) => {
            document.remove()
          })
        }

        const response = await post(this.urlValue, {
          body: JSON.stringify({ blob_key: blob.key, dom_id: id }),
          responseKind: "turbo-stream",
        })

        if (!response.ok) {
          document.getElementById(id).remove()
          throw new Error("Network response was not ok.")
        }
      } catch ({ error, id }) {
        const progressBar = document.getElementById(id)
        this.errorTarget.classList.remove("hidden")
        this.errorTarget.textContent = "Error uploading file"
        progressBar && progressBar.remove()
      }
    })
  }
}
