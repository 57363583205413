import ApplicationController from "../../../../javascript/application_controller"

import AirDatepicker from "air-datepicker"
import localeEn from "air-datepicker/locale/en"
import "air-datepicker/air-datepicker.css"

export default class extends ApplicationController {
  static targets = ["datePicker", "input", "buttonText"]

  connect() {
    if (this.inputTarget.value) {
      this.buttonTextTarget.textContent = this.inputTarget.value
      this.element.classList.add("todo-due-set")
    }

    this.picker = new AirDatepicker(this.datePickerTarget, {
      locale: localeEn,
      inline: true,
      dateFormat: "MM-dd-yyyy",
      startDate: this.inputTarget.value,
      minDate: new Date(),
      onSelect: ({ formattedDate }) => {
        if (formattedDate) {
          this.inputTarget.value = formattedDate
          this.buttonTextTarget.innerText = formattedDate
          this.element.classList.add("todo-due-set")
        } else {
          this.inputTarget.value = ""
          this.buttonTextTarget.innerText = "Set Due Date"
          this.element.classList.remove("todo-due-set")
        }

        this.dispatch("date-changed", { namespace: "" })
      },
    })
  }

  reset = (e) => {
    e.preventDefault()
    this.picker.clear()
  }
}
